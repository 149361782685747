import { useContext, useState } from "react";

import { Menu, Seo } from "~components";
import { BaseContext } from "~context";

import uniq from "lodash/uniq";
import { Layout } from "~components/common/Layout";
import { SubHeader } from "~components/overall";
import List from "~components/overall/overall.List";
import { useEventsService } from "~features/events/hooks/useEventsService";
import { MembershipsDocument } from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";

const Overall = () => {
  const { organization } = useContext(BaseContext);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState<string>(undefined);

  const { events, featuredEvents, isLoading, error } = useEventsService({
    search,
    includeFeaturedEvents: true,
  });

  const {
    data: membershipData,
    error: membershipsError,
    isLoading: membershipsLoading,
  } = useQuery(MembershipsDocument);

  const memberships = membershipData?.memberships.edges.map(({ node }) => node);

  const overall = [];

  if (memberships?.length > 0) {
    memberships.forEach((membership) =>
      overall.push({
        startDate: membership.startDate,
        endDate: membership.endDate,
        title: membership.name,
        id: membership.id,
        isMembership: true,
        hasPublicRelease: membership.id,
        locations: uniq(
          membership.events?.map(
            ({ venueAddress }) =>
              `${venueAddress.name}, ${venueAddress.address?.city}`
          )
        ),
      })
    );
  }

  if (events?.length > 0) {
    events.forEach((event) =>
      overall.push({
        startDate: event.startDate,
        endDate: event.endDate,
        title: event.title,
        id: event.id,
        isMembership: false,
        hasPublicRelease: event.hasPublicRelease,
        locations: [`${event.venue?.name}, ${event.venue?.address?.city}`],
      })
    );
  }

  overall.sort(
    (a, b) =>
      Date.parse(a?.startDate as string) - Date.parse(b?.startDate as string)
  );

  const toggleSearch = () => setShowSearch(!showSearch);

  return (
    <>
      <Seo title="Event Overview" description="Event overview page for" />
      <Menu>
        <SubHeader
          showSearch={showSearch}
          setSearch={setSearch}
          toggleSearch={toggleSearch}
          hasBackground={!!organization?.branding?.background}
        />
      </Menu>
      <Layout layoutType="main">
        <List
          isLoading={isLoading || membershipsLoading}
          error={error || membershipsError}
          setSearch={setSearch}
          events={overall}
          featuredEvents={featuredEvents}
        />
      </Layout>
    </>
  );
};

export default Overall;
